import React from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux'

class LfbPostlistSignup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      '1-2rooms': false,
      '3rooms': false,
      '4rooms': false,
      '5-6rooms': false,
      errors: {
        name: '',
        email: '',
      }
    }

    this.name = React.createRef()
    this.email = React.createRef()
  }

  onChange(event) {
    let key = event.currentTarget.name;
    let value = "";

    if (event.currentTarget.type === "checkbox") {
      value = event.currentTarget.checked;
    } else {
      value = event.currentTarget.value;
    }

    this.setState({
      [key]: value
    })
  }

  resetState() {
    this.setState(initialState);
  }

  validate() {
    let errors = this.state.errors;
    let emailCheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let valid = true;

    Object.keys(errors).forEach(key => {
      switch (key) {
        case 'name':
          let val = this.name.current.value;
          if (val !== "" && val.length > 0) {
            errors.name = ''
            valid = true;
          } else {
            errors.name = 'Fylla þarf út í nafn'
            valid = false
          }
          break;

        case 'email':
          let value = this.email.current.value
          if ((RegExp(emailCheck).test(value) && value.length > 0)) {
            errors.email = ''
            valid = true
          } else {
            errors.email = 'Netfang er ekki gilt';
            valid = false
          }
          break;

        default:
          break;

      }
    });
    this.setState({ errors: errors })
    return valid;
  }



  handleSubmit() {
    if (this.validate()) {
      let tags = [this.state['1-2rooms'] ? "1" : "", this.state['3rooms'] ? "2" : "", this.state['4rooms'] ? "3" : "", this.state['5-6rooms'] ? "4" : ""]
      fetch('/api/v1/lfb_postlisti/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': window.csrf_token,
        },
        body: JSON.stringify({ "email": this.state.email, "name": this.state.name, "tags": tags }),
      }).then(response => response.json())
        .then(data => {
          if (data.code === 200) {
            this.setState({
              name: '',
              email: '',
              '1-2rooms': false,
              '3rooms': false,
              '4rooms': false,
              '5-6rooms': false,
              signupMessage: data.message
            })
          } else {
            this.setState({ signupMessage: data.message })
          }
  
        })
    }
  }

  render() {
    return (
      <div className="vaktin-card-container">
        <div className="col-12 vaktin-card-title">
          <h2>Vaktin</h2>
        </div>
          <div className="vaktin-checkbox col-12">
            <ul className="">
              <li className="">
                <input type="checkbox" name="1-2rooms" id="1-2rooms" checked={this.state['1-2rooms']} onChange={this.onChange.bind(this)} />
                <span className="checkmark"></span>
                <label htmlFor="1-2rooms">1-2ja herbergja</label>
              </li>
              <li className="">
                <input type="checkbox" name="3rooms" id="3rooms" checked={this.state['3rooms']} onChange={this.onChange.bind(this)} />
                <span className="checkmark"></span>
                <label htmlFor="3rooms">3ja herbergja</label>
              </li>
              <li className="">
                <input type="checkbox" name="4rooms" id="4rooms" checked={this.state['4rooms']} onChange={this.onChange.bind(this)} />
                <span className="checkmark"></span>
                <label htmlFor="4rooms">4ja herbergja</label>
              </li>
              <li className="">
                <input type="checkbox" name="5-6rooms" id="5-6rooms" checked={this.state['5-6rooms']} onChange={this.onChange.bind(this)} />
                <span className="checkmark"></span>
                <label htmlFor="5-6rooms">5-6 herbergja</label>
              </li>
            </ul>
          </div>

          <div className="vaktin-user-info col-12">
            <div className="vaktin-name-input col-12 col-lg-6 px-0 pr-lg-4">
              <label htmlFor="vaktin-name">Nafn</label>
              <input ref={this.name} type="text" className={this.state.errors.name.length > 0 ? 'error' : ''} id="vaktin-name" name="name" value={this.state.name} onChange={this.onChange.bind(this)} />
              {this.state.errors.name.length > 0 ?
                <span className="error"> {this.state.errors.name} </span> : ''
              }
            </div>
            <div className="vaktin-email-input col-12 col-lg-6 pl-lg-4 px-0">
              <label htmlFor="vaktin-email">Netfang</label>
              <input ref={this.email} type="text" className={this.state.errors.email.length > 0 ? 'error' : ''} id="vaktin-email" name="email" value={this.state.email} onChange={this.onChange.bind(this)} />
              {this.state.errors.email.length > 0 ?
                <span className="error"> {this.state.errors.email} </span> : ''
              }
            </div>
            {this.state.signupMessage && <div className="signup-message col-12">{this.state.signupMessage}</div>}
            <a className="btn-primary btn-red" onClick={this.handleSubmit.bind(this)}>Vista vakt</a>
          </div>
      </div>
    )
  }
}

// LfbPostlistSignup.defaultProps = {
// }

// function mapStateToProps(state) {

//   var f = {
//     apartments: {},
//   }
//   f.apartments = (state.apartments === undefined) ? {} : state.apartments
//   return f;
// }

// export default connect(mapStateToProps)(LfbPostlistSignup);
export default LfbPostlistSignup;