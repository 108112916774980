import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import { join } from 'path';
import { reorderApplications } from '../applications/actions'

export const memberTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'MEMBERSHIP');
export const creditCardTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'REQUEST_TEMP_TOKEN', 'RECEIVE_TEMP_TOKEN', 'SUCCESS_TEMP_TOKEN', 'ERROR_TEMP_TOKEN','RECEIVE_TOKEN_STATUS', 'REQUEST_TOKEN_STATUS', 'ERROR_TOKEN_STATUS',], 'CREDIT_CARD');
export const paymentUpdateTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'PAYMENT');
export const cancelSubscriptionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'MEMBERSHIP_CANCEL')


export const requestMemberInfo = () => {
    return {
        type: memberTypes.REQUEST,
    };
};

export const receiveMemberInfo = (response, leiga) => {
    return {
        type: memberTypes.RECEIVE,
        leiga: leiga,
        data: response,
    };
};

export const receiveMemberInfoError = () => {
    return {
        type: memberTypes.ERROR,
    };
};

export const fetchMemberInfo = (leiga=false) => {
    return dispatch => {
        dispatch(requestMemberInfo());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        

        return fetch(`/api/v1/minarsidur/`, {
            headers: headers,
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveMemberInfo(response, leiga));
                } catch (e) {
                    dispatch(receiveMemberInfoError(response))
                }


            })
            .catch(handleNetworkError);
    }
}

export const sortApplications = (oldIndex, newIndex, applicationOrder) => {
    let appliOrder = applicationOrder[0]
    appliOrder = appliOrder.slice();
    appliOrder.splice(newIndex < 0 ? appliOrder.length + newIndex : newIndex, 0, appliOrder.splice(oldIndex, 1)[0])

    
    let newOrderedArray = [appliOrder.map((newItem, index) => {
        let newIndex = (index+1).toString();
        let applicationOrder = {};
        applicationOrder[newItem.recordId] = newIndex;
              
        return applicationOrder
    })]

    return dispatch => (dispatch(reorderApplications(newOrderedArray[0])));
}


export const requestUpdateCreditCard = () => {
    return {
        type: creditCardTypes.REQUEST,
    };
};

export const receiveUpdateCreditCard = (response, leiga) => {
    return {
        type: creditCardTypes.RECEIVE,
        leiga: leiga,
        data: response,
    };
};

export const receiveUpdateCreditCardError = () => {
    return {
        type: creditCardTypes.ERROR,
    };
};



export const updateCreditCard = (paymentObj) => {
    return dispatch => {
        dispatch(requestUpdateCreditCard());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        
        return fetch(`/api/v1/skiptaumkreditkort/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': window.csrf_token,
            },
            body: JSON.stringify(paymentObj),
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveUpdateCreditCard(response));
                } catch (e) {
                    dispatch(receiveUpdateCreditCardError(response))
                }


            })
            .catch(handleNetworkError);
    }
}

export const requestUpdateEmailAskell = () => {
    return {
        type: paymentUpdateTypes.REQUEST,
    };
};

export const receiveUpdateEmailAskell = (response) => {
    return {
        type: paymentUpdateTypes.RECEIVE,
        data: response,
    };
};

export const receiveUpdateEmailAskellError = () => {
    return {
        type: paymentUpdateTypes.ERROR,
    };
};

export const updatePaymentEmail = (email) => {
    return dispatch => {
        dispatch(requestUpdateEmailAskell());
        
        return fetch(`/api/v1/update_email_askell/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': window.csrf_token,
            },
            body: JSON.stringify({'email': email}),
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveUpdateEmailAskell(response));
                } catch (e) {
                    dispatch(receiveUpdateEmailAskellError(response))
                }


            })
            .catch(handleNetworkError);
    }
}

export const requestTemporaryToken = (response) => {
    return {
        type: creditCardTypes.REQUEST_TEMP_TOKEN,
        data: response
    };
};


export const receiveTemporaryToken = (response) => {
    return {
        type: creditCardTypes.RECEIVE_TEMP_TOKEN,
        data: response,
    };
};

export const receiveTemporaryTokenError = (response) => {
    return {
        type: creditCardTypes.ERROR_TEMP_TOKEN,
        data: response
    };
};

export const createTempToken = (cardObj, card_change=false) => {
    return dispatch => {
        dispatch(requestTemporaryToken())
 
        let success = true
        return fetch('https://askell.is/api/temporarypaymentmethod/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Api-Key ${window.ASKELL_PUBLIC_KEY}`,
            },
            body: JSON.stringify({
                'card_number': card_change ? cardObj['cardNumber'] : cardObj['payment_object']['payment_method']['card_number'],
                'expiration_year': card_change ? cardObj['expiration_year'] : cardObj['payment_object']['payment_method']['expiration_year'],
                'expiration_month': card_change ? cardObj['expiration_month'] : cardObj['payment_object']['payment_method']['expiration_month'],
                'cvv_number': card_change ? cardObj['cvc'] : cardObj['payment_object']['payment_method']['cvv_number'],
            })
        })
        .then(response => {
            if(response.status !== 201) {
                success = false
                if (response.status >= 500) {
                    dispatch(receiveTemporaryTokenError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(receiveTemporaryToken(response));
            } else {
                dispatch(receiveTemporaryTokenError(response))
            }
        })
        .catch(handleNetworkError);
    };
}


export const requestMembershipCancelation = () => {
    return {
        type: cancelSubscriptionTypes.REQUEST,
    };
};

export const receiveMembershipCancelation = (response) => {
    return {
        type: cancelSubscriptionTypes.RECEIVE,
        data: response,
    };
};

export const receiveMembershipCancelationError = () => {
    return {
        type: cancelSubscriptionTypes.ERROR,
    };
};


export const cancelSubscription = (process_step, file_obj) => {
    return dispatch => {
        
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestMembershipCancelation());

        return fetch(`/api/v1/cancel_subscription/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({"file_obj": file_obj ? file_obj : '', "process_step": process_step}),
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveMembershipCancelation(response, leiga));
                } catch (e) {
                    dispatch(receiveMembershipCancelationError(response))
                }

            })
            .catch(handleNetworkError);
    }
}


export const requestTokenStatus = () => {
    return {
        type: creditCardTypes.REQUEST_TOKEN_STATUS,
    };
};


export const receiveTokenStatus = (response) => {
    return {
        type: creditCardTypes.RECEIVE_TOKEN_STATUS,
        data: response,
    };
};

export const receiveTokenStatusError = (response) => {
    return {
        type: creditCardTypes.ERROR_TOKEN_STATUS,
        data: response
    };
};



export const fetchTokenStatus = (token) => {
    return dispatch => {

        dispatch(requestTokenStatus())
        let success = true
        return fetch(`https://askell.is/api/temporarypaymentmethod/${token}/`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Api-Key ${window.ASKELL_PUBLIC_KEY}`,
        }
        })
        .then(response => {
            if(response.status !== 200) {
                success = false
                if (response.status >= 500) {
                    dispatch(receiveTokenStatusError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(receiveTokenStatus(response));
            } else {
                dispatch(receiveTokenStatusError(response))
            }
        })
        .catch(handleNetworkError);
    };
  } 