import React from 'react';
import { connect } from 'react-redux'
import { getPrettierDateFormat, Spinner } from '../../utils';
import { SvgEditIcon } from '../svg'
import MyPaymentInformation from "../../components/my-payment-information";

const kennitala = require('kennitala');
import { cancelSubscription, fetchMemberInfo } from '../../api/members/actions'
import { fetchPDF } from '../../api/pdf_signing/actions';

class Cancellation extends React.Component {
    
    constructor(props) {
        super(props)

        this.state = {
            file : [],
            signingProcess: 'NOT_STARTED',
            pdfSigned: false,
            showBackButton: false,

        }

        this.signDocument = this.signDocument.bind(this)
        this.messageHandler = this.messageHandler.bind(this);
        this.getBackButton = this.getBackButton.bind(this);
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);

    }



    messageHandler(e) {
        if (e.data.type == 'signing_completed') {
            this.submitCancelation(e.data);
            this.setState({pdfSigned: true})
            this.setState({signingProcess: 'COMPLETED'})
        } else if (e.data.type == "already_signed") {
            this.setState({taktikalErrorMsg: 'Þetta skjal hefur þegar verið undirritað.'})
        } else if (e.data.type == 'process_not_found' || e.data.type == 'invalid_keys' || e.data.type == 'error') {
            this.setState({taktikalErrorMsg: 'Það kom upp villa við undirritun á skjalinu.'})
        }
    }


    submitCancelation(data){
        let processStep = 'start'
        this.props.dispatch(cancelSubscription(processStep, data.signedDocument))
    }
    
    componentDidMount(){
        this.props.dispatch(fetchPDF())
        window.addEventListener('message', this.messageHandler);
        
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.signingProcess !== this.state.signingProcess ) {
            if(this.state.signingProcess == 'IN_PROCESS') {
                this.setState({showBackButton: true})
            }
            else if(this.state.signingProcess == 'COMPLETED') {
                this.setState({showBackButton: false})
            }
        }
    }


    signDocument() {
        this.setState({signingProcess: 'IN_PROCESS'})
    }

    

    getModalContent(pdf){
        switch(this.state.signingProcess) {
            case 'NOT_STARTED':
                return(<div className="row justify-content-center">
                    <div className="col-12 title">Viltu segja upp félagsaðild?</div>
                <div className="col-12">Ertu viss?</div>
                <ul>
                    <li>Við úthlutun búseturétta nýtur lægsta félagsnúmer forgangs</li>
                    <li>Þegar þú vilt ganga aftur í félagið færðu nýtt félagsnúmer</li>
                </ul>

                
                {!this.props.pdf.isFetching && this.props.pdf.results.signees && this.props.pdf.results.signees.length > 0 ? 
                    <div className="col-12 text-center">
                        <a className="btn btn-primary btn-red" href="/nanar/nanar-um-felagsadild/" target="_blank">Ég vil hugsa málið</a>
                        <a className="btn btn-primary btn-red" onClick={() => this.signDocument()}>Ég er viss og vil staðfesta úrsögn</a> 
                    </div>
                    : 
                    <div className="col-12 loader-container">
                        <Spinner />
                    </div>
                }
                <div className="col-12 explanation">
                    Til að staðfesta úrsögn er rafrænt skjal undirritað með rafrænum skilríkjum.<br/>
                    Ef þú ert ekki með rafræn skilríki þá getur þú sent tölvupóst á <a href="mailto:buseti@buseti.is">buseti@buseti.is</a> eða komið við á skrifstofu okkar að Síðumúla 10.
                </div></div>)

            case 'IN_PROCESS' :
            case 'COMPLETED':
                return(<div className="iframe-container"><iframe src={pdf.results.signees[0].url} /></div>)

            case 'QUESTIONARE':
                return(<div className="iframe-container"><iframe className="questionare" width="640px" height= "480px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=c7H6SUog4UalPeWE--KiQqgedxy217lOu5M6kwwP4ZRUMjY3VzlOMjVWNllRTzFXTTFBSkwxU0pPSS4u&embed=true" frameborder= "0" marginwidth= "0" marginheight= "0" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe></div>)
            case 'QUESTIONARE_COMPLETE':
                this.props.dispatch(fetchMemberInfo())
            default: 
                return(<></>)
        }
    }

    cancelButtonClickHandler() {
        this.setState({signingProcess: 'NOT_STARTED'})
        this.setState({showBackButton: false})
        this.props.dispatch(fetchMemberInfo())
    }

    getBackButton() {
        if(this.state.showBackButton) {
            return <button className="btn btn-cancel" onClick={this.cancelButtonClickHandler}>Hætta við</button>
        }
    }

    render() {
        return (
            <>
            <div className="col-12 col-lg-7" id="cancel-subscription-modal">
                <div className="row">
                    <h2 className="col-12 pl-0 pr-0">Segja upp félagsaðild</h2>
                </div>
                { this.getBackButton() }
                <div className="row content">
                    <div className={ ['IN_PROCESS', 'COMPLETED'].includes(this.state.signingProcess) ? 'col-12' : `col-12 col-md-6 offset-md-3`}>
                        {this.state.signingProcess == 'COMPLETED' && <button className="btn btn-primary btn-red continue" onClick={() => this.setState({signingProcess: 'QUESTIONARE'})}>Halda áfram</button>}
                        {this.state.signingProcess == 'QUESTIONARE' && <button className="btn btn-primary btn-red continue" onClick={() => this.setState({signingProcess: 'QUESTIONARE_COMPLETE'})}>Halda áfram</button>}
                        {this.getModalContent(this.props.pdf)}
                    </div>
                </div>
            </div>
            </>
        )
    }
}


function mapStateToProps(state) {

    var f = {
        pdf: {},
    }
    f.pdf = (state.pdf === undefined) ? {} : state.pdf

    return f;
}


export default connect(mapStateToProps)(Cancellation);