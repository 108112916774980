window.leiga = true
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap';
import 'isomorphic-fetch'; 
require('object-assign');
require ('es7-object-polyfill')

import Parsley from 'parsleyjs';
window.Parsley = Parsley;


require('../../buseti/app/views/apartment-container')
require('../../buseti/app/views/singleApartment')
require('../../buseti/app/views/three-empty-apartments')
require('../../buseti/app/views/becomeMember')
require('../../buseti/app/views/myPages')
require('../../buseti/app/views/childPayment')
require('../../buseti/app/views/lfbMyPages')
require('../../buseti/app/views/lfbPostlistSignup')
require('../../buseti/app/views/dropdownMenu')


import "../../../sass/lfb.scss"